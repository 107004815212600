body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.timeline-with-icons {
  border-left: 1px solid hsl(0, 0%, 90%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-with-icons .success .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(103, 88%, 90%);
  color: hsl(109, 89%, 35%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.timeline-with-icons .danger .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(0, 88%, 90%);
  color: hsl(0, 89%, 35%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-with-icons .warning .timeline-icon {
  position: absolute;
  left: -48px;
  background-color: hsl(36, 88%, 90%);
  color: hsl(57, 89%, 35%);
  border-radius: 50%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grad-text {
  background: rgb(29,253,42);
  background: linear-gradient(135deg, rgba(29,253,42,1) 0%, rgba(29,253,196,1) 25%, rgba(0,224,12,1) 50%, rgba(186,253,29,1) 75%, rgba(29,253,42,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grad-text-alt {
  background: rgb(29,253,42);
  background: linear-gradient(55deg, rgb(0, 208, 105) 0%, rgb(0, 253, 90) 52%, rgb(91, 244, 149) 73%, rgb(0, 250, 6) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.grad-feature-card {
  border-radius: 20px!important;
  background: linear-gradient(55deg, rgba(243,243,243,1) 0%, rgba(243,243,243,1) 52%, rgb(145, 239, 154) 73%, rgb(14, 237, 20) 100%)!important;
}

.grad-feature-card-static {
  border-radius: 20px!important;
  background: rgba(243,243,243,1)!important;
}

.grad-feature-card-alt {
  border-radius: 20px!important;
  border: rgba(243,243,243,1)!important;
  background: linear-gradient(55deg, rgba(243,243,243,1) 0%, rgba(243,243,243,1) 52%, rgba(245,204,255,1) 73%, rgba(130,217,254,1) 90%, rgba(42,225,231,1) 100%)!important;
}

.grad-bg {
  background: rgb(58,180,65);
  background: linear-gradient(180deg, rgb(0, 250, 6) 0%, rgb(0, 250, 6) 9%, rgba(0,255,6,1) 50%, rgb(0, 250, 6) 100%);
}

.grad-bg-blue {
  background: linear-gradient(55deg, rgb(67, 191, 243) 0%, rgb(209, 126, 243) 52%, rgba(245,204,255,1) 73%, rgba(130,217,254,1) 90%, rgba(42,225,231,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#grad-bg-blue {
  background: linear-gradient(55deg, rgb(67, 191, 243) 0%, rgb(209, 126, 243) 52%, rgba(245,204,255,1) 73%, rgba(130,217,254,1) 90%, rgba(42,225,231,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hori-timeline .events {
  border-top: 3px solid #e9ecef;
}
.hori-timeline .events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}
.hori-timeline .events .event-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}
.hori-timeline .events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 38px;
  margin: 0 auto;
  border-radius: 100px;
  padding: 2px 4px;
}
@media (min-width: 1140px) {
  .hori-timeline .events .event-list {
    display: inline-table;
    width: 24%;
    padding-top: 45px;
  }
  .hori-timeline .events .event-list .event-date {
    top: -12px;
  }
}
.bg-soft-primary {
  background-color: rgba(64, 124, 203, 0.3) !important;
}
.bg-soft-success {
  background-color: rgba(71,189,154,.3)!important;
}
.bg-soft-danger {
  background-color: rgba(231,76,94,.3)!important;
}
.bg-soft-warning {
  background-color: rgba(249,213,112,.3)!important;
}

.research-card {
  min-height: 26vh;
  background: rgb(243,243,243)!important;
  transition: background-color .4s ease, transform .4s ease, box-shadow .4s ease;
}

.research-card:hover {
  transform: translate3D(0,-1px,0) scale(1.05);
  transition: all .4s ease;
  /*box-shadow: 8px 28px 50px rgba(0, 0, 0, 0.07), 1px 6px 12px rgba(0, 0, 0, 0.04);*/

}

.research-card::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -1;
  background: linear-gradient(55deg, rgba(67, 191, 243, 0.5) 0%, rgba(209, 126, 243, 0.5) 52%, rgba(245, 204, 255, 0.5) 73%, rgba(130, 217, 254, 0.5) 90%, rgba(42,225,231,1) 100%);
  opacity: 0;
  transition: opacity .4s ease;
  border-radius: inherit;
}

.research-card:hover::before {
  opacity: 1;
}


.card-popout {
  min-height: 26vh;
  transition: background-color .4s ease, transform .4s ease, box-shadow .4s ease;
}

.card-popout:hover {
  transform: translate3D(0,-1px,0) scale(1.05);
  transition: all .4s ease;
  box-shadow: 25px 25px 25px rgba(0, 0, 0, 0.17);

}

.card-popout::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  z-index: -1;
  background: linear-gradient(55deg, rgb(67, 191, 243) 0%, rgb(209, 126, 243) 52%, rgba(245,204,255,1) 73%, rgba(130,217,254,1) 90%, rgba(42,225,231,1) 100%);
  opacity: 0;
  transition: opacity .4s ease;
  border-radius: inherit;
}

.card-popout:hover::before {
  opacity: 1;
}

.noise:after {
  position: absolute;
  pointer-events: none;
  background-image: url(https://gsap.com/img/noise.png);
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  opacity: .3;
  right: 0;
  top: 0;
  width: 100%;
}

.ki-wi-title{
  font-size: clamp(8rem, 15vw, 20rem);
  white-space: nowrap;
}

.fixed-section {
  position: sticky;
  scroll-behavior: smooth;
  top: 0.5vh;
  min-height: 80vh;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-section {
  min-height: 100vh;
  background-attachment: fixed;
  background-color: #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
}