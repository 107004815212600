.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #000;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 2px solid transparent;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(#000, #000),
  linear-gradient(90deg, rgba(51,203,235,1), rgba(40,214,98,1), rgba(240,119,57,1), rgba(227,54,107,1));
  animation: border-glow 3s linear infinite;
  z-index: 1000;
  border-radius: 5px;
  transition: all 0.5s ease; /* Suaviza a transição */
}
